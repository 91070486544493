/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "Pacifico";
  src: url("/assets/fonts/Pacifico-Regular.ttf");
}

@font-face {
  font-family: "DaxPro";
  src: url("/assets/fonts/Dax-Pro-Regular.ttf");
}

* {
  font-family: "DaxPro" !important;
}

ion-toolbar {
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-secondary-contrast);
  --border-width: 0;

  ion-button {
    --color: var(--ion-color-secondary-contrast) !important;
    --color-activated: #ee5a5a !important;
  }

  ion-back-button {
    --color: var(--ion-color-secondary-contrast) !important;
  }

  ion-title {
    //font-family: "Pacifico";
    font-size: 20px;
    font-weight: bold;
  }
}

// ion-tab-bar {
//   --background: #4f4f4f;

//   ion-tab-button {
//     --color: #939393;
//     --color-selected: #fff;
//   }
// }

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bigDisplay {
  font-size: 24px;
}

.info {
  font-size: 20px;
  font-weight: bold;
}

// imagem de fundo /////////////////
.fundo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //background: url(/assets/img/fundo_home.png) top center / cover no-repeat;
  background: url(/assets/img/fundo_geral.png);
  background-size: 115%;
  background-position: bottom;
  z-index: -1000;
}

.fundo-padrao {
  &::part(background) {
    background: url(/assets/img/fundo_home.png) top center / cover no-repeat;
  }
}

// input forms /////////////////////////////////////////////
.form {
  ion-input,
  ion-select {
    margin-bottom: 16px;
    border: 1px solid #666;
    border-radius: 8px;
    padding: 2px 12px !important;
    background-color: #222222aa;
    font-size: 120% !important;
    padding: 16px 0;
  }
}

.picker-opt.picker-opt-selected {
  color: var(--ion-color-secondary, #ff3f38);
}

@media (min-width: 768px) {
  body {
    background-color: transparent !important;
  }
  ion-app {
    max-width: 768px;
    margin: auto;
    position: relative;
  }

  .scroll-content {
    overflow-y: auto;
  }
}

.bold {
  font-weight: bold;
}

.native-input {
  margin: 16px 0 !important;
}

.label-text {
  color: #e50008 !important;
}
