// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: linear-gradient(#7c7c7c, #363636); //#4f4f4f;
  --ion-color-primary-rgb: 79, 79, 79;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #565656;
  --ion-color-primary-tint: #727272;

  --ion-color-secondary: #ce2026;
  --ion-color-secondary-rgb: 242, 0, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #d50000;
  --ion-color-secondary-tint: #f31a1a;

  --ion-color-tertiary: linear-gradient(#ce2026, #a50000);
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #e00000;
  --ion-color-tertiary-tint: #ff1a1a;

  --ion-color-success: #009051;
  --ion-color-success-rgb: 0, 144, 81;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #007f47;
  --ion-color-success-tint: #1a9b62;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 179, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e09e00;
  --ion-color-warning-tint: #ffbb1a;

  --ion-color-danger: #ee5f4f;
  --ion-color-danger-rgb: 238, 95, 79;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #d15446;
  --ion-color-danger-tint: #f06f61;

  --ion-color-dark: #000;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #e2e2e2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #c7c7c7;
  --ion-color-medium-tint: #e5e5e5;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
